import { EngineerEmotionSmileIcon, Stub } from '@/shared';
import { OperationAlertProps } from './alert.types';

export const RoutemapCloseAlert = ({ techMapNumber }: OperationAlertProps) => {
	return (
		<Stub
			icon={<EngineerEmotionSmileIcon />}
			header="Маршрутная карта закрыта"
			label={
				<p>
					Все работы по маршрутной карте <strong>№{techMapNumber}</strong> завершены
				</p>
			}
		/>
	);
};

import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { IOperation, ITechCard, LINK_TO_TECH_CARD_INFO, TechCardStatuses, TECH_CARDS_PATH } from '@/shared/core';
import { FormT, useFormConfig } from './formConfig';

import { useChangeStatusMutation, useGetEmployeeInitialsQuery, useGetMaterialsQuery } from '@/shared/state/api';

import { Loader } from '@/shared/components/loader';
import { Button, ButtonGroup, IconButton, TextHeader, Tooltip, useToast } from '@chakra-ui-kraud/react';
import { ArrowBackIcon, ChevronRightIcon } from '@chakra-ui/icons';

import { CanceledStatusModal } from '@/entities/tables/tech-card';
import { showErrorToast, showSuccessToast, SubmitModal, useShowTechCardButtons, useTechCard } from '@/shared';
import { STANDARD_CONSUMPTION_TYPES, TechCardStatusColors } from '@/shared/constants';
import { Layout } from '@/widgets';
import { TechCard } from '@/widgets/tech-card';

import { getTechCardName } from '@/shared/core/utils/tech-card-utils';
import {
	StandardConsumptionTypeEnum,
	TechMapRequestCreate,
	useCreateTechMapMutation,
} from '@/shared/state/api/swagger';
import { LinkTechCardModal } from '@/widgets';
import { MadIcon } from 'madsoft-icons';
import { ActionsTechCardMenu, LinkMkStateType } from './actions-tech-card-menu';
import { CancelModal } from './cancel-modal/cancel-modal';
import { LinkSchemaSideModal } from './link-schema-side-modal';
import { PopoverTechCard } from './popover/popover-techcard';
import styles from './tech-card-page.module.scss';
import { convertNumberToNumberStringWithDot } from '@/shared/core/utils/convert-string-to-numbet-string';

export const TechCardPage = () => {
	const toast = useToast();
	const location = useLocation();
	const navigate = useNavigate();

	const {
		// объект с данными о тех-карте
		cardInfo,
		// объект с состоянием получения объекта карты (isSuccess, isFailed, isLoading)
		getCardInfo,
		// номер карты (из урла)
		cardNumber,
		// режим создания новой карты
		isCreationMode,
		// режим редактирования существующей карты
		isEditMode,
		setIsEditMode,
		isInitialEditMode,
		userRole,
		// сабмит для обновления существующей карты
		handleSubmit,
		// изменение статуса карты
		changeStatusToCanceled,
		// флаг обновления
		isUpdating,
	} = useTechCard();

	const printeableAreaRef = useRef<HTMLDivElement>(null);

	const [isLinkTechCardModalOpen, setIsLinkTechCardModalOpen] = useState<LinkMkStateType>(() => {
		return { isOpen: false, variant: 'create' };
	});
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [isResetMode, setIsResetMode] = useState(false);
	const [isLinkSchemaModalOpen, setIsLinkSchemaModalOpen] = useState(false);
	const [submitModal, setSubmitModal] = useState<null | {
		remainder: string;
		standardConsumptionType: StandardConsumptionTypeEnum;
	}>(null);

	const [isPrinting, setIsPrinting] = useState(false);

	const { data: materials } = useGetMaterialsQuery();
	const { data: employee } = useGetEmployeeInitialsQuery();

	const [changeStatus, changeStatusInfo] = useChangeStatusMutation();

	const formProps = useFormConfig(isCreationMode, userRole, cardInfo);

	const [create, createInfo] = useCreateTechMapMutation();

	const handlePrint = useReactToPrint({
		content: () => printeableAreaRef.current,
		removeAfterPrint: true,
		copyStyles: true,
		onAfterPrint: () => {
			setIsPrinting(false);
			navigate(TECH_CARDS_PATH);
		},
	});

	useEffect(() => {
		if (location.state?.isPrinting && printeableAreaRef.current) {
			setIsPrinting(true);
			handlePrint();
		}
	}, [location.state, handlePrint]);

	useEffect(() => {
		if (changeStatusInfo.isError) {
			showErrorToast(toast, {
				description:
					changeStatusInfo.originalArgs?.status === TechCardStatuses.accepted_at_sgd
						? 'При передаче на склад произошла ошибка'
						: 'При выдаче произошла ошибка',
			});
		}
		if (changeStatusInfo.isSuccess) {
			showSuccessToast(toast, {
				description:
					changeStatusInfo.originalArgs?.status === TechCardStatuses.accepted_at_sgd
						? `Карта № ${getTechCardName(cardInfo)} успешно отправлена на склад`
						: `Карта № ${getTechCardName(cardInfo)} успешно выдана`,
			});
		}
	}, [changeStatusInfo.isError, changeStatusInfo.isSuccess]);

	const prepareRequest = (data: FormT): TechMapRequestCreate => {
		const { name, file, extra_name, symbol, material, extra_symbol } = data.product || {};

		return {
			symbol: data.symbol,
			product: {
				name: name ?? '',
				extra_name,
				symbol: symbol ?? '',
				extra_symbol,
				material: {
					symbol: material?.symbol,
					gost: material?.gost,
					sortament: material?.sortament,
					gost_na_sortament: material?.gost_na_sortament,
				},
				approval_card: data?.product?.approval_card ?? '',
				extra_approval_card: data?.product?.extra_approval_card ?? '',
			},
			created_at: data.created_at ? dayjs(data.created_at, 'DD.MM.YYYY', true).format() : dayjs().format(),
			// @ts-ignore
			operations: data?.operations?.map((operation: IOperation) => ({
				...operation,
			})),
			num: {
				num: data?.num?.num || 0,
				symbol: data?.num?.symbol,
				gost: data?.num?.gost,
				sortament: data?.num?.sortament,
				gost_na_sortament: data?.num?.gost_na_sortament,
				number_of_melt: data?.num?.number_of_melt,
				date_of_manufacture: data?.num?.date_of_manufacture ? data?.num?.date_of_manufacture : undefined,
				o_v_or_date_of_manufacture: data?.num?.o_v_or_date_of_manufacture,
				o_v: data?.num?.o_v,
				replace_decision: data?.num?.replace_decision,
				standard_consumption_type: data?.num?.standard_consumption_type || 'old',
			},
			plan: {
				fio: data?.plan?.fio,
				issurance_count: data?.plan?.issurance_count
					? Number(String(data?.plan?.issurance_count).replace(',', '.'))
					: undefined,
				list_number: data?.plan?.list_number,
				count: Number(data?.plan?.count),
				NR_kg: data.plan.NR_kg ? convertNumberToNumberStringWithDot(data.plan.NR_kg) : undefined,
				NR_meters: data.plan.NR_meters ? convertNumberToNumberStringWithDot(data.plan.NR_meters) : undefined,
				NR_square_meters: data.plan.NR_square_meters
					? convertNumberToNumberStringWithDot(data.plan.NR_square_meters)
					: undefined,
				NR_grams: data.plan.NR_grams ? convertNumberToNumberStringWithDot(data.plan.NR_grams) : undefined,
			},
			file_id: file?.id,
		};
	};

	const handleCreateTechCard = ({ force }: { force: boolean }) => {
		formProps.handleSubmit((data) => {
			const techMapRequestCreate = prepareRequest(data);

			create({ techMapRequestCreate, 'x-disable-catalog-num-remainder-check': force ? 'true' : undefined })
				.unwrap()
				.then((data) => {
					showSuccessToast(toast, {
						title: 'Маршрутная карта создана',
						description: `Вы создали карту № ${getTechCardName(data)}`,
						duration: 6000,
					});
					setIsEditMode(false);

					if (data?.number) {
						navigate(LINK_TO_TECH_CARD_INFO(data?.number), {
							state: { isPrinting: true },
						});
					}
				})
				.catch((error) => {
					if (error.status === 479) {
						setSubmitModal({
							remainder: error?.data?.detail[0]?.remainder,
							standardConsumptionType: error?.data?.detail[0]?.standard_consumption_type,
						});
					} else {
						showErrorToast(toast, {
							title: 'Не удалось сохранить',
							description: 'При создании карты произошла ошибка',
						});
					}
				});
		})();
		setIsPrinting(true);
		setSubmitModal(null);
	};

	const handleCancelClick = () => {
		formProps.reset();
		setIsEditMode(false);
		if (isInitialEditMode || isCreationMode || isEditMode) {
			navigate(TECH_CARDS_PATH);
		}
	};

	const isLoaded = !createInfo.isLoading && !changeStatusInfo.isLoading && !isUpdating && !getCardInfo.isLoading;

	const {
		// отображение кнопки "Редактировать"
		hideEditMkButton,
	} = useShowTechCardButtons({ cardInfo, userRole });

	return (
		<>
			<Layout
				withSidebar={false}
				backArrow={
					<Button
						as={IconButton}
						icon={<ArrowBackIcon />}
						size="md"
						variant="ghost"
						colorScheme="tertiary"
						backgroundColor="transparent"
						onClick={() => {
							if (!isEqual(formProps.formState.defaultValues, formProps.getValues()) && isEditMode) {
								setIsCancelModalOpen(true);
							} else {
								handleCancelClick();
								navigate(TECH_CARDS_PATH);
							}
						}}
					/>
				}
				title={
					<>
						<TextHeader className={styles['tech-card__title']} variant="h1">
							{isCreationMode
								? 'Создание карты'
								: isEditMode
								? `Редактирование карты № ${getTechCardName(cardInfo)}`
								: `Маршрутная карта № ${getTechCardName(cardInfo)}`}
						</TextHeader>
						{cardInfo?.status && (
							<span
								style={{
									backgroundColor: `${TechCardStatusColors[cardInfo?.status]}`,
									width: '8px',
									height: '8px',
									minWidth: '8px',
									minHeight: '8px',
									borderRadius: '50%',
								}}
							/>
						)}
					</>
				}
				headerLeftButton={
					isEditMode && !isCreationMode ? (
						<ButtonGroup>
							<Button
								size="md"
								variant="ghost"
								colorScheme="tertiary"
								onClick={() => {
									if (!isEqual(formProps.formState.defaultValues, formProps.getValues())) {
										setIsCancelModalOpen(true);
									} else {
										handleCancelClick();
									}
								}}
								tabIndex={-1}
							>
								Отменить
							</Button>
							<Tooltip isDisabled={formProps.formState.isValid} label={'Заполните нужные поля'}>
								<Button
									size="md"
									variant="ghost"
									colorScheme="primary"
									rightIcon={
										<MadIcon
											module="basic"
											type="outline"
											size="default"
											mode="default"
											name="chevron-right"
										/>
									}
									onClick={() => {
										if (isEqual(formProps.formState.defaultValues, formProps.getValues())) {
											setIsEditMode(false);
										} else {
											formProps.handleSubmit(() => {
												handleSubmit(
													formProps.formState?.defaultValues as Partial<ITechCard>,
													formProps.getValues(),
													{ exitAfterSubmit: false },
												);
											})();
										}
									}}
									tabIndex={-1}
									isDisabled={!formProps.formState.isValid || !isLoaded}
								>
									Сохранить изменения
								</Button>
							</Tooltip>
						</ButtonGroup>
					) : !isEditMode && !isCreationMode ? (
						hideEditMkButton || cardInfo?.status === TechCardStatuses.canceled ? null : (
							<Button
								size="md"
								variant="ghost"
								colorScheme="tertiary"
								leftIcon={
									<MadIcon
										module="basic"
										size="default"
										mode="default"
										type="outline"
										name="pencil-square"
									/>
								}
								onClick={() => setIsEditMode(true)}
							>
								Отредактировать
							</Button>
						)
					) : (
						<Tooltip label={'Заполните обязательные поля'} isDisabled={!!formProps.formState.isValid}>
							<Button
								size="md"
								variant="ghost"
								colorScheme="primary"
								lineHeight="24px"
								rightIcon={<ChevronRightIcon />}
								isDisabled={!formProps.formState.isValid || createInfo.isLoading || !!createInfo.data}
								onClick={() => handleCreateTechCard({ force: false })}
								tabIndex={-1}
							>
								Создать и напечатать
							</Button>
						</Tooltip>
					)
				}
				headerRightButton={
					!isCreationMode &&
					!isEditMode && (
						<ButtonGroup>
							<Button
								size="md"
								variant="ghost"
								colorScheme="tertiary"
								leftIcon={
									<MadIcon
										module="basic"
										type="outline"
										size="default"
										mode="default"
										name="printer"
									/>
								}
								onClick={() => {
									setIsPrinting(true);
									setTimeout(() => {
										handlePrint();
									}, 10);
								}}
							>
								Распечатать
							</Button>
							<ActionsTechCardMenu
								setIsLinkSchemaModalOpen={setIsLinkSchemaModalOpen}
								setIsResetMode={setIsResetMode}
								setLinkVariant={setIsLinkTechCardModalOpen}
							/>
						</ButtonGroup>
					)
				}
			>
				<form autoComplete="off">
					{cardInfo?.number && (
						<LinkSchemaSideModal
							isOpen={isLinkSchemaModalOpen}
							title={'История связей'}
							onClose={() => setIsLinkSchemaModalOpen(false)}
							cardNumber={cardInfo?.number}
						/>
					)}
					<LinkTechCardModal
						isOpen={isLinkTechCardModalOpen.isOpen}
						onClose={() =>
							setIsLinkTechCardModalOpen((prev) => {
								return { ...prev, isOpen: false };
							})
						}
						variant={isLinkTechCardModalOpen.variant}
						title={
							isLinkTechCardModalOpen.variant === 'create'
								? 'Создание связи с новой МК'
								: 'Создание связи с существующей МК'
						}
						cardInfo={cardInfo}
						employee={employee}
					/>
					<CancelModal
						isCreationMode={isCreationMode}
						isCancelModalOpen={isCancelModalOpen}
						onClose={() => setIsCancelModalOpen(false)}
						handleMainAction={() => {
							if (isEqual(formProps.formState.defaultValues, formProps.getValues())) {
								setIsEditMode(false);
								navigate(TECH_CARDS_PATH);
							} else {
								formProps.handleSubmit(() => {
									handleSubmit(
										formProps.formState?.defaultValues as Partial<ITechCard>,
										formProps.getValues(),
										{ exitAfterSubmit: true },
									);
								})();
							}
						}}
						handleCancelClick={handleCancelClick}
					/>

					<FormProvider {...formProps}>
						{isLoaded ? (
							<>
								<TechCard
									isPrinting={isPrinting}
									isCreationMode={isCreationMode}
									isEditable={isEditMode}
									ref={printeableAreaRef}
									cardInfo={cardInfo || (createInfo.data as ITechCard)}
									materials={materials || []}
									focusedBlock={location.state?.focusedBlock ? location.state.focusedBlock : null}
								/>
								<PopoverTechCard
									isCreationMode={isCreationMode}
									isInitialEditMode={isInitialEditMode}
									isEditMode={isEditMode}
								/>
							</>
						) : (
							<Loader />
						)}
					</FormProvider>
				</form>
			</Layout>
			{isResetMode && (
				<CanceledStatusModal
					isOpen={isResetMode}
					onClose={() => setIsResetMode(false)}
					onCancelTechMap={changeStatusToCanceled}
				/>
			)}
			{submitModal && (
				<SubmitModal
					isOpen={!!submitModal}
					onClose={() => setSubmitModal(null)}
					onSubmit={() => handleCreateTechCard({ force: true })}
					text={`Сейчас на складе ${submitModal.remainder} ${
						STANDARD_CONSUMPTION_TYPES[submitModal.standardConsumptionType]
					}. материала. Вы уверены, что хотите создать карту с таким материалом?`}
					closeBtnText="Изменить материал"
					submitBtnText="Создать карту"
				/>
			)}
		</>
	);
};

import type { FC } from 'react';
import type { NumCatalogFormProps } from './num-catalog-form.types';

import { yupResolver } from '@hookform/resolvers/yup';
import _debounce from 'lodash/debounce';
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Flex } from '@chakra-ui-kraud/react';
import { DefaultOptionType } from 'antd/es/select';

import { CalendarSinglePicker, CustomSelect, NotFoundContentDropdown } from '@/shared';
import { CustomInput } from '@/shared/components/custom-input';
import {
	useLazyGetInvoicesFilterByDateQuery,
	useLazyGetInvoicesFilterByNumberQuery,
	useLazyGetManufacturesQuery,
	useLazyGetSuppliersQuery,
} from '@/shared/state/api/swagger';

import styles from './num-catalog-form.module.scss';
import { numValidationScheme, oldNumValidationScheme } from './utils/validation';
import { convertToOptions } from './utils/convert-to-options';

export const NumCatalogForm: FC<NumCatalogFormProps> = ({ onSubmit, selectedNum = {}, reference, variant }) => {
	const maxDate = useMemo(() => new Date(2050, 1), []);

	const [suppliersSearch, setSuppliersSearch] = useState('');
	const [manufacturesSearch, setManufacturesSearch] = useState('');
	const [invoicesByNumberSearch, setInvoicesByNumberSearch] = useState('');

	const [invoiceNumberOptions, setInvoiceNumberOptions] = useState<DefaultOptionType[]>([]);
	const [manufacturesOptions, setManufacturesOptions] = useState<DefaultOptionType[]>([]);
	const [suppliersOptions, setSuppliersOptions] = useState<DefaultOptionType[]>([]);

	const [getInvoicesByNumber, invoicesByNumber] = useLazyGetInvoicesFilterByNumberQuery();
	const getInvoicesByNumberAbort = useRef<(() => void) | null>(null);

	const [getInvoicesByDate, invoicesNumberByDate] = useLazyGetInvoicesFilterByDateQuery();
	const getInvoicesByDateAbort = useRef<(() => void) | null>(null);

	const [getSuppliers, suppliers] = useLazyGetSuppliersQuery();
	const getSuppliersAbort = useRef<(() => void) | null>(null);

	const [getManufactures, manufactures] = useLazyGetManufacturesQuery();
	const getManufacturesAbort = useRef<(() => void) | null>(null);

	useImperativeHandle(reference, () => ({
		submitForm() {
			handleSubmit(onSubmit)();
		},
	}));
	const {
		control,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
	} = useForm({
		defaultValues: {
			...selectedNum,
		},
		mode: 'onSubmit',
		resolver: yupResolver(
			variant === 'edit' && !selectedNum.date_of_delivery ? oldNumValidationScheme : numValidationScheme,
		),
	});

	const debouncedInvoicesNumberSearch = useCallback(
		_debounce((stringToSearch: string) => {
			const { abort } = getInvoicesByNumber({ stringToSearch });
			getInvoicesByNumberAbort.current = abort;
		}, 200),
		[],
	);
	const debouncedInvoicesDateSearch = useCallback(
		_debounce((stringToSearch: string) => {
			const { abort } = getInvoicesByDate({ filterDate: stringToSearch });
			getInvoicesByDateAbort.current = abort;
		}, 200),
		[],
	);
	const debouncedSuppliersSearch = useCallback(
		_debounce((stringToSearch: string) => {
			const { abort } = getSuppliers({ stringToSearch });
			getSuppliersAbort.current = abort;
		}, 200),
		[],
	);
	const debouncedManufacturesSearch = useCallback(
		_debounce((stringToSearch: string) => {
			const { abort } = getManufactures({ stringToSearch });
			getManufacturesAbort.current = abort;
		}, 200),
		[],
	);

	const onDateOfDeliverySelect = useCallback((date: string) => {
		setValue('date_of_delivery', date ? date : undefined);
	}, []);
	const onDateOfDeliveryReset = useCallback(() => {
		setValue('date_of_delivery', '');
	}, []);

	const onDateOfManufactureSelect = useCallback((date: string) => {
		setValue('date_of_manufacture', date ? date : undefined);
	}, []);
	const onDateOfManufactureReset = useCallback(() => {
		setValue('date_of_manufacture', '');
	}, []);

	const onValidUntilSelect = useCallback((date: string) => {
		setValue('valid_until', date ? date : undefined);
	}, []);
	const onValidUntilReset = useCallback(() => {
		setValue('valid_until', '');
	}, []);

	const onInvoiceDateReset = useCallback(() => {
		setValue('invoice.date', '');
	}, []);
	const onInvoiceDateSelect = useCallback(
		(date: string) => {
			const currentDate = getValues('invoice.date');
			if (currentDate !== date) {
				setValue('invoice.date', date ? date : '', {
					shouldValidate: true,
				});

				getInvoicesByDateAbort.current?.();
				debouncedInvoicesDateSearch(date);
			}
		},
		[getValues],
	);

	useEffect(() => {
		const options = [...(invoicesByNumber.data?.payload ?? []), ...(invoicesNumberByDate.data?.payload ?? [])];

		setInvoiceNumberOptions(
			convertToOptions(options, invoicesByNumberSearch, {
				key: 'number',
			}),
		);
	}, [invoicesByNumber, invoicesNumberByDate]);
	useEffect(() => {
		setSuppliersOptions(
			convertToOptions(suppliers?.data?.payload, suppliersSearch, {
				key: 'name',
			}),
		);
	}, [suppliers]);
	useEffect(() => {
		setManufacturesOptions(
			convertToOptions(manufactures.data?.payload, manufacturesSearch, {
				key: 'name',
			}),
		);
	}, [manufactures]);

	useEffect(() => {
		getInvoicesByNumberAbort.current?.();
		debouncedInvoicesNumberSearch(invoicesByNumberSearch);
	}, [invoicesByNumberSearch]);
	useEffect(() => {
		getSuppliersAbort.current?.();
		debouncedSuppliersSearch(suppliersSearch);
	}, [suppliersSearch]);
	useEffect(() => {
		getManufacturesAbort.current?.();
		debouncedManufacturesSearch(suppliersSearch);
	}, [manufacturesSearch]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles['form']}>
			<Controller
				control={control}
				name="num"
				render={({ field }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="НУМ"
						defaultValue={selectedNum?.num}
						value={field.value}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.num?.message}
						tooltipContent={errors.num?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="date_of_delivery"
				render={({ field: { value }, fieldState: { error } }) => (
					<CalendarSinglePicker
						showTooltip={!!error}
						isInvalid={variant === 'edit' && !selectedNum?.date_of_delivery ? !!error : !!error || !value}
						tooltipContent={error?.message}
						// параметр maxDate, чтобы каленьдарь не был ограничен сегодняшним числом
						maxDate={maxDate}
						// ввиду странного поведения сброса значения на бэке, приходится использовать null и пустую строку для удаления даты в нуме / создания нума с датой
						onSelectDate={onDateOfDeliverySelect}
						onResetDate={onDateOfDeliveryReset}
						value={value}
						defaultDate={getValues('date_of_delivery') ?? null}
						label="Дата поступления материала"
					/>
				)}
			/>
			<Flex flexDirection="row" gap={2}>
				<Controller
					control={control}
					name="invoice.number"
					render={({ field, fieldState: { error } }) => (
						<CustomSelect
							isInvalid={!!error}
							value={field.value}
							defaultValue={field.value ? String(field.value) : undefined}
							showSearch
							labelInValue
							notFoundContent={
								<NotFoundContentDropdown
									isFetchingNaming={invoicesByNumber.isFetching || invoicesNumberByDate.isFetching}
									alertName="Введите номер"
								/>
							}
							label="Номер С/Ф"
							options={invoiceNumberOptions}
							onChange={(value) => {
								setValue('invoice.number', value.value);
								setValue(
									'invoice.date',
									invoicesByNumber.data?.payload.find((invoice) => invoice.number === value.value)
										?.date || '',
								);
							}}
							onClear={() => setValue('invoice.number', '')}
							searchValue={invoicesByNumberSearch}
							onSearch={setInvoicesByNumberSearch}
						/>
					)}
				/>
				<Controller
					control={control}
					name="invoice.date"
					render={({ fieldState: { error }, field }) => (
						<CalendarSinglePicker
							width="200px"
							showTooltip={!!error}
							isInvalid={!!error}
							tooltipContent={error?.message}
							maxDate={maxDate}
							onSelectDate={onInvoiceDateSelect}
							value={field.value || null}
							onResetDate={onInvoiceDateReset}
							defaultDate={getValues('invoice.date') || null}
							label="Дата (от)"
						/>
					)}
				/>
			</Flex>
			<Controller
				control={control}
				name="symbol"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="Материал"
						defaultValue={selectedNum?.symbol}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.symbol?.message}
						tooltipContent={errors.symbol?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="gost"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="Стандарт материала"
						defaultValue={selectedNum.gost}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.gost?.message}
						tooltipContent={errors.gost?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="sortament"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="Сортамент"
						defaultValue={selectedNum.sortament}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.sortament?.message}
						tooltipContent={errors.sortament?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="gost_na_sortament"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="Стандарт сортамента"
						defaultValue={selectedNum.gost_na_sortament}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.gost_na_sortament?.message}
						tooltipContent={errors.gost_na_sortament?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="standard_consumption_type"
				render={({ field, fieldState: { error } }) => (
					<CustomSelect
						allowClear={false}
						size="large"
						{...field}
						options={[
							{ value: 'meters', label: 'Метр' },
							{ value: 'square_meters', label: 'Метр квадратный' },
							{ value: 'kg', label: 'Килограмм' },
							{ value: 'grams', label: 'Грамм' },
						]}
						isInvalid={!field.value}
						showTooltip={!!errors.standard_consumption_type?.message}
						tooltipContent={errors.standard_consumption_type?.message}
						label="Единица нормы расхода"
						defaultValue={selectedNum.standard_consumption_type}
					/>
				)}
			/>
			<Controller
				control={control}
				name="quantity"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value || !!error?.message}
						label="Количество"
						defaultValue={selectedNum?.quantity}
						value={field.value}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.quantity?.message}
						tooltipContent={errors.quantity?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="certificate"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!!error}
						label="Сертификат"
						defaultValue={selectedNum.certificate}
					/>
				)}
			/>
			<Controller
				control={control}
				name="batch_number"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!!error}
						label="№ партии"
						defaultValue={selectedNum.batch_number}
					/>
				)}
			/>
			<Controller
				control={control}
				name="number_of_melt"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!field.value}
						label="№ плавки"
						defaultValue={selectedNum.number_of_melt}
						styleTooltip={'num-catalog-tooltip'}
						showTooltip={!!errors.number_of_melt?.message}
						tooltipContent={errors.number_of_melt?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="divisions"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!!error}
						label="Мех. свойства"
						defaultValue={selectedNum.divisions}
					/>
				)}
			/>
			<Controller
				control={control}
				name="n_b"
				render={({ field, fieldState: { error } }) => (
					<CustomInput size="md" {...field} isInvalid={!!error} label="НВ" defaultValue={selectedNum.n_b} />
				)}
			/>
			<Controller
				control={control}
				name="o_v"
				render={({ field, fieldState: { error } }) => (
					<CustomInput label="σв" size="md" {...field} isInvalid={!!error} defaultValue={selectedNum.o_v} />
				)}
			/>
			<Controller
				control={control}
				name="date_of_manufacture"
				render={({ field, fieldState: { error } }) => (
					<CalendarSinglePicker
						// параметр maxDate, чтобы каленьдарь не был ограничен сегодняшним числом
						maxDate={maxDate}
						// ввиду странного поведения сброса значения на бэке, приходится использовать null и пустую строку для удаления даты в нуме / создания нума с датой
						onSelectDate={onDateOfManufactureSelect}
						onResetDate={onDateOfManufactureReset}
						defaultDate={getValues('date_of_manufacture') ?? null}
						label="Дата изготовления"
					/>
				)}
			/>
			<Controller
				control={control}
				name="valid_until"
				render={({ field, fieldState: { error } }) => (
					<CalendarSinglePicker
						// параметр maxDate, чтобы каленьдарь не был ограничен сегодняшним числом
						maxDate={maxDate}
						// ввиду странного поведения сброса значения на бэке, приходится использовать null и пустую строку для удаления даты в нуме / создания нума с датой
						onSelectDate={onValidUntilSelect}
						onResetDate={onValidUntilReset}
						defaultDate={getValues('valid_until') ?? null}
						label="Годен до"
					/>
				)}
			/>
			<Controller
				control={control}
				name="validity"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!!error}
						label="Годность"
						defaultValue={selectedNum.validity}
					/>
				)}
			/>
			<Controller
				control={control}
				name="supplier.name"
				render={({ field }) => (
					<CustomSelect
						value={field.value}
						showSearch
						defaultValue={field.value}
						labelInValue
						notFoundContent={
							<NotFoundContentDropdown
								isFetchingNaming={suppliers.isFetching}
								alertName="Введите поставщика"
							/>
						}
						label="Организация поставщик"
						options={suppliersOptions}
						onChange={(value) => {
							setValue('supplier.name', value.value);
						}}
						onClear={() => setValue('supplier.name', '')}
						searchValue={suppliersSearch}
						onSearch={setSuppliersSearch}
					/>
				)}
			/>
			<Controller
				control={control}
				name="manufacturer.name"
				render={({ field }) => (
					<CustomSelect
						value={field.value}
						showSearch
						defaultValue={field.value}
						labelInValue
						notFoundContent={
							<NotFoundContentDropdown
								isFetchingNaming={manufactures.isFetching}
								alertName="Введите изготовителя"
							/>
						}
						label="Организация изготовитель"
						options={manufacturesOptions}
						onChange={(value) => {
							setValue('manufacturer.name', value.value);
						}}
						onClear={() => setValue('manufacturer.name', '')}
						searchValue={manufacturesSearch}
						onSearch={setManufacturesSearch}
					/>
				)}
			/>

			<Controller
				control={control}
				name="comment"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						{...field}
						isInvalid={!!error}
						label="Комментарий"
						defaultValue={selectedNum.comment}
					/>
				)}
			/>
		</form>
	);
};

import { Tooltips } from '@/shared/constants';
import * as yup from 'yup';

export const numValidationScheme = yup.object({
	num: yup.string().matches(/^\d+$/, Tooltips.NUMBER_REQUIRED).required(Tooltips.PRODUCT_CATALAG_TEXT_NUM),
	date_of_delivery: yup.string().required(Tooltips.DATE_REQUIRED),
	symbol: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	gost: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	sortament: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	gost_na_sortament: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	number_of_melt: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	date_of_manufacture: yup.string().notRequired().default(null),
	standard_consumption_type: yup.string().required(Tooltips.SELECT_TEXT),
	quantity: yup
		.number()
		.typeError(Tooltips.NUMBER_REQUIRED)
		.required(Tooltips.PRODUCT_CATALAG_TEXT_NUM)
		.positive(Tooltips.PRODUCT_CATALAG_TEXT_NUM),
	invoice: yup.object().shape(
		{
			number: yup
				.string()
				.when(['date'], (date: (string | undefined)[]) =>
					!!date[0] && date[0]?.length > 0
						? yup.string().min(1, Tooltips.SELECT_TEXT).required(Tooltips.SELECT_TEXT)
						: yup.string().nullable(),
				),
			date: yup
				.string()
				.when(['number'], (number: (string | undefined)[]) =>
					!!number[0] && number[0]?.length > 0
						? yup.string().min(1, Tooltips.SELECT_TEXT).required(Tooltips.SELECT_TEXT)
						: yup.string().nullable(),
				),
		},
		[['number', 'date']],
	),
});

export const oldNumValidationScheme = numValidationScheme.concat(
	yup.object({
		date_of_delivery: yup.string().nullable(),
	}),
);

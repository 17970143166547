import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import { FocusedBlock, Block, TechCardProps } from './tech-card.types';
import { useGetEmployeeInitialsQuery, useLazyGetOperationLinkHistoryByIdQuery } from '@/shared/state/api';
import { useAppSelector } from '@/shared/state';
import { IHistoryOperation, IOperation, TechCardStatuses, UserRoles } from '@/shared/core';

import { MainBlock, PlanBlock, ConsumptionBlock, OperationsBlock, DefaultOperationsBlock } from './blocks';
import { mainBlockId, pirntAreaBlockId } from '@/shared/constants';
import { SGDBlock } from './blocks';
import { Loader } from '@/shared';
import { Flex } from '@chakra-ui-kraud/react';

import styles from './tech-card.module.scss';
import dayjs from 'dayjs';

export const TechCard = forwardRef<HTMLDivElement, TechCardProps>(
	({ cardInfo, isEditable, isCreationMode, materials, isPrinting, focusedBlock }, ref) => {
		const [focused, setIsFocused] = useState<FocusedBlock>(() => {
			return {
				block: focusedBlock ? focusedBlock : null,
				focusedBy: focusedBlock ? 'tab' : null,
				atCurrentBlock: focusedBlock ? true : false,
			};
		});
		const [isLoading, setIsLoading] = useState(true);
		const userRole = useAppSelector((state) => state.auth.userProfile?.role);

		const { data: employee } = useGetEmployeeInitialsQuery();
		const [getOperationHistory, getHistory] = useLazyGetOperationLinkHistoryByIdQuery();

		const employeeForSelect = employee?.map((el) => ({
			value: el.id,
			label: el.initials,
		}));

		useEffect(() => {
			if (focusedBlock) {
				setIsFocused((prev) => {
					return { ...prev, block: focusedBlock, focusedBy: 'tab', atCurrentBlock: true };
				});
				return;
			}

			document.addEventListener('mousedown', function () {
				setIsFocused((prev) => ({ ...prev, focusedBy: 'mouse' }));
			});

			document.addEventListener('keydown', function (e) {
				if ((e.shiftKey && e.key === 'Tab') || e.key === 'Tab') {
					setIsFocused((prev) => ({
						...prev,
						focusedBy: 'tab',
					}));
				}
			});
			return () => {
				document.removeEventListener('mousedown', function () {
					setIsFocused((prev) => ({ ...prev, focusedBy: 'mouse' }));
				});

				document.removeEventListener('keydown', function (e) {
					if ((e.shiftKey && e.key === 'Tab') || e.key === 'Tab') {
						setIsFocused((prev) => ({
							...prev,
							focusedBy: 'tab',
						}));
					}
				});
			};
		}, [focusedBlock]);

		useEffect(() => {
			if (!isEditable) {
				setIsFocused({
					block: null,
					focusedBy: null,
					atCurrentBlock: false,
				});
			}
		}, [isEditable]);

		const handleFocus = (value: Block) => {
			setIsFocused((prev) => ({
				...prev,
				block: value,
				atCurrentBlock: prev.block === value,
			}));
		};

		const handleResize = useCallback(() => {
			const inner = document.getElementById(pirntAreaBlockId);
			const mainBLock = document.getElementById(mainBlockId);
			if (!inner) return;
			const innerBase = inner.scrollWidth;
			const width = mainBLock?.clientWidth;
			if (!width) return;

			if (width < innerBase) {
				inner.style.transform = `scale(${width / (innerBase + 24)})`;
				inner.style.transformOrigin = '0 0';
			} else {
				inner.style.transform = 'scale(1)';
				inner.style.transformOrigin = '0 0';
			}
		}, []);

		// hotfix
		// нормально не получается скейлить карту, у которой есть связи
		// TODO
		handleResize();

		useEffect(() => {
			handleResize();
			window.addEventListener('resize', handleResize);
			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}, []);

		// получаем данные всех связанных операций в карте
		const historedOperations = useMemo(() => {
			const promises: Promise<void>[] = [];
			// результат для печати - массив объектов, где ключ объекта - id операции, а значение - вся история связей этой операции в виде массива объектов
			const resultForPrint: Record<number, IHistoryOperation[]>[] = [];
			// результат для блока операций - массив, где ключ объекта - id операции, а значение - индекс с единицы (для сносок в печати)
			const resultForOperationsBlock: Record<number, number> = {};

			// ищем операции, у которых есть связь с другими мк
			const linkedOperations = cardInfo?.operations
				?.concat(cardInfo.default_operations as IOperation[])
				.filter((operation) => operation.has_link)
				.sort((a, b) => a.step_id - b.step_id);

			// собираем массив объектов этих операций
			linkedOperations?.map((operation, index) => {
				promises.push(
					getOperationHistory({ operation_id: Number(operation.id) })
						.unwrap()
						.then(async (res) => {
							// собираем объект с историей связей для операции, где
							// ключ - id операции, значение - массив истории связей
							resultForPrint.push({ [Number(operation.id)]: res });
						})
						.catch((err) => console.log(err)),
				);
			});

			// ждем когда все промисы выполнятся и убираем лоадер
			Promise.allSettled(promises)
				.then(() => setIsLoading(false))
				.catch((err) => console.log(err));

			// собираем объект для блока операций со сносками
			let startIndex = 1;

			linkedOperations?.forEach((historyOperation, index) => {
				// ключ - id операции, а значение - индекс операции (для символа степени)
				resultForOperationsBlock[Number(historyOperation.id)] = startIndex;
				startIndex += 1;
			});

			// если в текущей карте нет операций со связями - убираем лоадер
			if (!linkedOperations?.length) {
				setIsLoading(false);
			}

			return { resultForPrint, resultForOperationsBlock };
		}, [cardInfo]);

		// функция, генерирующая строку с историей связей для конкретной операции
		const generateLinkedOperationsForPrint = (
			historedOperations: Record<number, IHistoryOperation[]>[],
			indexes: Record<number, number>,
		) => {
			// const content: any[] = [];
			const content: { id: number; value: string }[] = [];

			// перебираем массив с операциями и их историями получения/передачи деталей
			historedOperations.map((operationWithHistory, index) => {
				// объект с конкретной операцией и её историей связей
				Object.entries(operationWithHistory).forEach(([key, value], index) => {
					const result: string[] = [];

					// перебираем историю связей в операции и составляем итоговую строку
					value.forEach((history, index) => {
						result.push(
							`${dayjs(history.date).format('DD.MM.YYYY')} ${
								history.direction === 'to' ? 'получение' : 'перенос'
							} ${history.count_number} штук ${history.direction === 'to' ? 'из' : 'в'} МК ${
								history.tech_map_number
							} (${history.responsible_employee_fio})`,
						);
					});

					//hotfix
					content.push({ id: indexes[Number(key)], value: result.join(', ') });
				});
			});
			const result: string[] = [];

			// hotfix (для сохранения индексов)
			content
				.sort((a, b) => a.id - b.id)
				.forEach((item, index) => {
					Object.entries(item).map(([key, value]) => {
						if (typeof value === 'string') {
							result.push(value);
						}
					});
				});

			return result;
		};

		return !isLoading ? (
			<div className={styles['tech-card']} id={pirntAreaBlockId} ref={ref}>
				{/* тут будет сгенерированный текст для истории связей операции */}
				{generateLinkedOperationsForPrint(
					historedOperations.resultForPrint,
					historedOperations.resultForOperationsBlock,
				).map((content, index) => {
					return (
						// класс будет отображать этот контент только при печати
						// сначала индекс операции (сноска как в примечаниях), затем контент (сгенерированная строка из функции renderHistoredOperationsOnPrint)
						<p className={styles['history-operations-print']} key={content}>
							<sup>{index + 1}</sup> {content}
						</p>
					);
				})}
				<Flex gap="9px" alignItems="stretch" className={styles['main_flex']}>
					<MainBlock
						isEditable={
							isEditable &&
							(!cardInfo || [UserRoles.admin, UserRoles.senior_operator].includes(userRole as UserRoles))
						}
						materials={materials}
						cardInfo={cardInfo}
						focusedBlock={focused}
						setFocusedBlock={focused.block === 'select_date_conservation' ? undefined : handleFocus}
					/>
					<PlanBlock
						isPrinting={isPrinting}
						isCreationMode={isCreationMode}
						isEditable={
							isEditable &&
							(cardInfo?.status === TechCardStatuses.progress ||
								[UserRoles.admin, UserRoles.senior_operator].includes(userRole as UserRoles) ||
								!cardInfo)
						}
						cardInfo={cardInfo}
						focusedBlock={focused}
						setFocusedBlock={handleFocus}
						employee={employeeForSelect || []}
					/>
					<ConsumptionBlock
						isCreationMode={isCreationMode}
						isEditable={
							isEditable &&
							!!cardInfo &&
							(cardInfo.status === TechCardStatuses.progress || userRole === UserRoles.admin)
						}
						cardInfo={cardInfo}
						focusedBlock={focused}
						setFocusedBlock={handleFocus}
					/>
				</Flex>
				<Flex flexDir="column" className={styles['operations_sgd_flex']}>
					<OperationsBlock
						employee={employeeForSelect || []}
						isEditable={
							isEditable &&
							!!cardInfo &&
							(cardInfo.status === TechCardStatuses.progress || userRole === UserRoles.admin)
						}
						focusedBlock={focused}
						setFocusedBlock={handleFocus}
						historedOperations={historedOperations.resultForOperationsBlock}
					/>

					<SGDBlock
						isPrinting={isPrinting}
						employee={employeeForSelect || []}
						isEditable={
							isEditable &&
							!!cardInfo &&
							(cardInfo.status === TechCardStatuses.progress ||
								cardInfo.status === TechCardStatuses.accepted_at_sgd ||
								cardInfo.status === TechCardStatuses.partially_issued ||
								userRole === UserRoles.admin)
						}
						cardInfo={cardInfo}
						focusedBlock={focused}
						setFocusedBlock={focused.block === 'select_date_conservation' ? handleFocus : undefined}
					/>
				</Flex>
			</div>
		) : (
			<Loader />
		);
	},
);

import type { FC } from 'react';
import { Table, Tr, Th, Td, Flex, Input, chakra, Text, Divider } from '@chakra-ui-kraud/react';
import { ConsumptionBlockTypes } from './consumption.types';
import defaultStyles from '../../tech-card.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './consumption.module.scss';
import { clsx } from 'clsx';
import { StandardConsumptionTypeEnum } from '@/shared/state/api/swagger';
import { STANDARD_CONSUMPTION_TYPES } from '@/shared/constants';

export const ConsumptionBlock: FC<ConsumptionBlockTypes> = ({
	isEditable,
	isCreationMode,
	cardInfo,
	focusedBlock,
	setFocusedBlock,
}) => {
	const { control, getValues } = useFormContext();

	return (
		<Flex marginTop={'-1px'} className={styles['consumption-block']}>
			<Table
				flex={1}
				className={clsx(
					focusedBlock?.block === 'consumption' &&
						focusedBlock.focusedBy === 'tab' &&
						!focusedBlock.atCurrentBlock &&
						defaultStyles.focused,
					isEditable && styles['edit-table'],
					'table',
				)}
				onFocus={() => setFocusedBlock && setFocusedBlock('consumption')}
			>
				<Tr>
					<Th colSpan={2} className={styles['without_border_bottom']}>
						<Flex alignItems={'center'} justifyContent={'center'}>
							<p style={{ marginRight: '6px', marginLeft: '6px', lineHeight: '1' }}>Лист № 1</p>
						</Flex>
					</Th>
				</Tr>
				<Tr>
					<Th colSpan={2} className={styles['without_border_top']}>
						<Flex alignItems="center" justifyContent="center">
							{cardInfo?.qr_code ? (
								<img
									alt="qr"
									src={`data:image/png;base64,${cardInfo?.qr_code}`}
									className={styles['qr_code']}
								/>
							) : (
								<div className={styles['qr_code']} />
							)}
						</Flex>
					</Th>
				</Tr>
				<Tr>
					<Th style={{ padding: '0px' }} colSpan={2}>
						КС / ИИ
					</Th>
				</Tr>
				<Tr className={getValues().product.extra_name ? styles['kcii_double'] : styles['kcii']}>
					<Td colSpan={2} padding={'0 !important'}>
						{isEditable ? (
							getValues().product.extra_name ? (
								<chakra.div>
									<Controller
										name="product.approval_card"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												maxHeight="30px"
												tabIndex={6}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												name="product.approval_card"
											/>
										)}
									/>
									<Divider opacity={1} w="100% !important" h={'1px !important'} />
									<Controller
										name="product.extra_approval_card"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												maxHeight="30px"
												mb="1px"
												tabIndex={6}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												name="product.extra_approval_card"
											/>
										)}
									/>
								</chakra.div>
							) : (
								<Controller
									name="product.approval_card"
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<Input
											p={0}
											tabIndex={6}
											isInvalid={!!error}
											onChange={onChange}
											value={value}
											name="product.approval_card"
										/>
									)}
								/>
							)
						) : cardInfo?.product.extra_name || getValues('product.extra_name') ? (
							<chakra.div display="flex" flexDirection="column" gap="5px" p={0}>
								<Text className={styles['print_bold_font']}>
									{cardInfo?.product.approval_card ?? getValues('product.approval_card')}
								</Text>
								<Text>
									{cardInfo?.product.extra_approval_card ?? getValues('product.extra_approval_card')}
								</Text>
							</chakra.div>
						) : (
							<Text>{cardInfo?.product.approval_card ?? getValues('product.approval_card')}</Text>
						)}
					</Td>
				</Tr>
				<Tr>
					<Th colSpan={2}>Техническое решение</Th>
				</Tr>
				<Tr className={styles['tech_solution']}>
					<Td colSpan={2}>
						{isEditable ? (
							<Controller
								name="plan.tech_decision"
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										p={0}
										backgroundColor="transparent"
										tabIndex={6}
										isInvalid={!!error}
										onChange={onChange}
										value={value}
										name="plan.tech_decision"
									/>
								)}
							/>
						) : (
							<>{cardInfo?.plan?.tech_decision}</>
						)}
					</Td>
				</Tr>
				<Tr>
					<Th style={{ textAlign: 'left', verticalAlign: 'middle', paddingLeft: '10px' }}>
						ИТОГО Расход
						<br /> материала,{' '}
						{
							STANDARD_CONSUMPTION_TYPES[
								getValues('num.standard_consumption_type') as StandardConsumptionTypeEnum
							]
						}
					</Th>
					<Td minWidth={'100px'}>
						{isEditable && !!cardInfo ? (
							<Controller
								name="plan.summary"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<Input
										maxW={'100px'}
										maxH={'47px'}
										backgroundColor="transparent"
										tabIndex={6}
										isInvalid={!!error}
										{...field}
									/>
								)}
							/>
						) : (
							<>{getValues().plan.summary}</>
						)}
					</Td>
				</Tr>
			</Table>
		</Flex>
	);
};

import { format } from 'date-fns';
import { FC, useImperativeHandle } from 'react';
import { Controller } from 'react-hook-form';

import { LinkTechCardFormProps } from './link-techcard-form.types';

import { CalendarSinglePicker, CustomInput, CustomSelect, IProduct, usePrepareDataForLinkTechCard } from '@/shared';
import { chakra, Text, Tooltip } from '@chakra-ui-kraud/react';
import { Select } from 'antd';

import { useGetProductsQuery } from '@/shared/state/api';
import { NotFoundContentDropdown } from '@/shared';
import clsx from 'clsx';
import styles from './link-techcard-form.module.scss';

export const LinkTechCardForm: FC<LinkTechCardFormProps> = ({
	cardInfo,
	employee,
	reference,
	onSubmit,
	variant,
	tooltipErrorContent,
	resetTooltipErrors,
}) => {
	const { data: products } = useGetProductsQuery({ page_size: 99999 });

	const {
		// детали (обозначения) для новой МК
		prepareProductSelect,
		// операции из выбранного материала (обозначения) для новой МК
		selectedProductOperations,
		// список техкарт, с которыми можно создать связь
		techCardsInProcess,
		// список сотрудников (ФИО)
		employeeForSelect,
		// функция, которая формирует операции после выбора обозначения для новой МК
		handleSelectData,
		// функция, которая определяет выбранную операцию (дефолтную или обычную)
		handleSetOperationValue,
		// loader для получения техкарт, с которыми можно создать связь
		isTechCardsInProcessLoading,
		// форма
		control,
		handleSubmit,
		setValue,
	} = usePrepareDataForLinkTechCard({ cardInfo, products: products?.payload ?? [], employee, variant });

	// сабмитим форму
	useImperativeHandle(reference, () => ({
		submitForm() {
			handleSubmit((data) => {
				onSubmit({ data });
			})();
		},
	}));

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles['link-techcard-form']} ref={reference}>
			{/* При создании новой связанной МК - выбираем обозначение, а при связи с существующей МК - выбираем номер МК */}
			{variant === 'create' ? (
				<Controller
					control={control}
					// обозначение новой мк
					name="children_product_catalog_id"
					render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
						<CustomSelect
							label="Выберите обозначение для новой МК"
							options={prepareProductSelect}
							isInvalid={!!error?.message}
							allowClear={true}
							showSearch={true}
							{...field}
							onChange={(e) => {
								onChange(e);
								// после выбора обозначения новой МК, составляем список операций для селекта "Куда"
								handleSelectData(Number(e));
							}}
						/>
					)}
				/>
			) : (
				<Controller
					control={control}
					// id существующей карты, с которой создаем связь
					name="children_tech_map_id"
					render={({ field: { value, onChange, ...field }, fieldState: { error, isTouched } }) => {
						return (
							<Tooltip
								isOpen={!isTouched && !!tooltipErrorContent.children_tech_map_id?.message}
								label={tooltipErrorContent.children_tech_map_id?.message}
								placement="bottom-start"
								borderRadius="base"
								bg="danger.300"
								top="-5px"
							>
								<div>
									<Select
										className={clsx(
											styles['select'],
											tooltipErrorContent.children_tech_map_id?.message && [
												styles['select-invalid'],
											],
										)}
										showSearch
										placeholder={'Введите номер МК'}
										virtual={false}
										options={techCardsInProcess}
										filterOption={(input, option) =>
											String(option?.label ?? '')
												.toLowerCase()
												.includes(input.toLowerCase())
										}
										onChange={(e) => {
											onChange(e);
										}}
										onClick={() => resetTooltipErrors()}
										dropdownStyle={{ zIndex: 1400 }}
										size={'large'}
										notFoundContent={
											<NotFoundContentDropdown
												isFetchingNaming={isTechCardsInProcessLoading}
												alertName={'Введите номер МК'}
											/>
										}
									/>
								</div>
							</Tooltip>
						);
					}}
				/>
			)}
			{variant === 'create' && (
				<>
					<Controller
						control={control}
						// план, штук
						name="plan_count"
						render={({ field: { onChange, ...field }, fieldState: { error } }) => (
							<CustomInput
								size="md"
								{...field}
								isInvalid={!!error}
								label="Введите план по количеству деталей"
								defaultValue={undefined}
								value={field.value ?? ''}
								onChange={(e) => {
									onChange(e.target.value.replace(/\D/g, ''));
								}}
							/>
						)}
					/>
					<Controller
						control={control}
						// ФИО ПДС
						name="fio"
						render={({ field: { onChange, ...field }, fieldState: { error } }) => (
							<CustomSelect
								initialValue={undefined}
								label="Выберите ФИО ПДС"
								options={employeeForSelect}
								isInvalid={!!error}
								showSearch={true}
								{...field}
								onChange={(e) => {
									onChange(e);
								}}
							/>
						)}
					/>
					<Controller
						control={control}
						// куда
						name="children_operation_type_id"
						render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
							<Select
								className={clsx(styles['select'], error && [styles['select-invalid']])}
								virtual={false}
								size="large"
								placeholder="Куда"
								dropdownStyle={{ zIndex: 10000 }}
								notFoundContent={
									<NotFoundContentDropdown
										isFetchingNaming={false}
										alertName={'Сначала выберите обозначение для новой МК'}
									/>
								}
								onSelect={(e) => {
									handleSetOperationValue(e);
								}}
							>
								{selectedProductOperations.map((operation, index) => {
									return (
										<option
											key={String(operation.label)}
											selected={String(operation.label) === String(value)}
											value={String(operation.label)}
										>
											{operation.label}
										</option>
									);
								})}
							</Select>
						)}
					/>
				</>
			)}
			<Controller
				control={control}
				// фио ответственного
				name="responsible_employee_fio"
				render={({ field: { onChange, ...field }, fieldState: { error } }) => (
					<CustomSelect
						initialValue={undefined}
						label="Выберите ответственного"
						options={employeeForSelect}
						isInvalid={!!error}
						showSearch={true}
						{...field}
						onChange={(e) => {
							onChange(e);
						}}
					/>
				)}
			/>
			<Controller
				control={control}
				// дата
				name="from_parent_created_at"
				render={({ field, fieldState: { error } }) => (
					<CalendarSinglePicker
						onSelectDate={(date) => {
							setValue('from_parent_created_at', date);
						}}
						defaultDate={format(new Date(), 'yyyy-MM-dd')}
						label={'Дата'}
					/>
				)}
			/>
			<chakra.div display="flex" justifyContent="space-between" gap="12px">
				<Controller
					control={control}
					// кол-во грамм
					name="count_gram"
					rules={{ required: false, value: 0 }}
					render={({ field: { onChange, ...field }, fieldState: { error } }) => (
						<CustomInput
							size="md"
							{...field}
							isRequired={false}
							value={field.value ?? 0}
							isInvalid={Number(field?.value) > Number(cardInfo?.current_operation?.count_in_gram)}
							label="Количество грамм"
							defaultValue={0}
							changeLabelColor={true}
							bottomDescription={`Максимально ${cardInfo?.current_operation?.count_in_gram ?? '-'} грамм`}
							onChange={(e) => {
								onChange(Number(e.target.value.replace(/\D/g, '')));
							}}
						/>
					)}
				/>
				<Controller
					control={control}
					// кол-во штук
					name="count_number"
					render={({ field: { onChange, ...field }, fieldState: { error } }) => (
						<CustomInput
							size="md"
							{...field}
							isInvalid={
								!!error || Number(field?.value) > Number(cardInfo?.current_operation?.count_in_number)
							}
							label="Количество штук"
							defaultValue={undefined}
							changeLabelColor={true}
							bottomDescription={`Максимально ${
								cardInfo?.current_operation?.count_in_number ?? '-'
							} штук`}
							onChange={(e) => {
								onChange(e.target.value.replace(/\D/g, ''));
							}}
						/>
					)}
				/>
			</chakra.div>
			<Text fontSize="md" lineHeight={6} fontWeight="normal" mt={'12px'}>
				При переносе всех деталей в другую маршрутную карту, текущая карта перейдет в статус{' '}
				<strong>«Завершена»</strong>
			</Text>
		</form>
	);
};

import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import type { IProduct } from '@/shared/core';
import type { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface';

import { Actions, CommonTable, DeleteModal, Loader, showErrorToast, showSuccessToast } from '@/shared';
import { useToast } from '@chakra-ui-kraud/react';
import { TablePaginationConfig } from 'antd';

import { CellTableParagraph } from '@/shared/components/cell-table-paragraph/cell-table-paragraph';
import {
	AssemblyCatalogResponse,
	AssemblyCatalogSortingFields,
	NumCatalogSortingFields,
	useDeleteAssemblyMutation,
} from '@/shared/state/api/swagger';
import styles from './assembly-catalog-table.module.scss';
import { AssemblyCatalogTableProps } from './num-catalog-table.types';

export const AssemblyCatalogTable: FC<AssemblyCatalogTableProps> = ({
	data,
	page,
	pageSize,
	total,
	setQueryParams,
	isLoading,
	handleClick,
}) => {
	const toast = useToast();
	const [deleteModal, setDeleteModal] = useState(false);
	const [selectedAssembly, setSelectedAssembly] = useState<AssemblyCatalogResponse | null>(null);
	const [deleteAssembly] = useDeleteAssemblyMutation();

	const handleDeleteAssembly = (assemblyId: number) => {
		deleteAssembly({ assemblyId })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, {
					title: 'Сборочная единица удалена',
					description: `Вы удалили ${selectedAssembly?.symbol}`,
				});
			})
			.catch(() => {
				showErrorToast(toast, {
					title: 'Не удалось удалить сборочную единицу',
					description: `Ошибка при удалении ${selectedAssembly?.symbol}`,
				});
			});
		setDeleteModal(false);
		setSelectedAssembly(null);
	};

	const ASSEMBLY_CATALOG_TABLE: ColumnsType<AssemblyCatalogResponse> = useMemo(() => {
		const onHeaderCell: ColumnsType<AssemblyCatalogResponse>[number]['onHeaderCell'] = (column) => {
			return {
				style: {
					width: '100%',
					whiteSpace: 'normal',
				},
			};
		};

		return [
			{
				key: 'symbol',
				title: 'Обозначение',
				dataIndex: 'symbol',
				sorter: true,
				className: styles['symbol'],
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
			},
			{
				key: 'name',
				title: 'Наименование',
				dataIndex: 'name',
				sorter: true,
				className: styles['name'],
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
			},
			{
				key: 'actions',
				title: 'Действия',
				align: 'left',
				dataIndex: 'actions',
				className: styles['actions'],
				render: (_: ReactNode, row: AssemblyCatalogResponse) => {
					return (
						<Actions
							width={120}
							onDelete={() => {
								setSelectedAssembly(row);
								setDeleteModal(true);
							}}
							onEdit={() => {
								handleClick?.(row, 'edit');
							}}
							withTemplate
							onCreate={() => {
								handleClick?.(row, 'create');
							}}
							tooltip={{
								duplicate: 'Создать похожую сборочную единицу',
								edit: 'Редактировать сборочную единицу',
								del: 'Удалить сборочную единицу',
							}}
						/>
					);
				},
				onHeaderCell,
				fixed: 'right',
			},
		];
	}, []);

	const handleTableChange = useCallback(
		(
			pagination: TablePaginationConfig,
			filters: Record<string, FilterValue | null>,
			sorter:
				| SorterResult<Partial<AssemblyCatalogSortingFields>>
				| SorterResult<Partial<AssemblyCatalogSortingFields>>[],
		) => {
			const sortedBy = Array.isArray(sorter) ? sorter[0].column?.key?.toString() : sorter.column?.key?.toString();
			const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
			const preparedSortDir = sortOrder ? (sortOrder === 'ascend' ? 'asc' : 'desc') : undefined;

			setQueryParams((prev) => ({
				...prev,
				order_by: sortedBy as NumCatalogSortingFields,
				order_dir: preparedSortDir,
			}));
		},
		[],
	);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<CommonTable
						columns={ASSEMBLY_CATALOG_TABLE}
						data={data}
						onChange={handleTableChange}
						pageSize={pageSize}
						total={total}
						page={page}
						setQueryParams={setQueryParams}
					/>
					{deleteModal &&
						createPortal(
							<DeleteModal
								isOpen={deleteModal}
								onClose={() => {
									setDeleteModal(false);
									setSelectedAssembly(null);
								}}
								onDelete={() => handleDeleteAssembly(Number(selectedAssembly?.id))}
								text={`Вы уверены, что хотите удалить сборочную единицу ${selectedAssembly?.symbol}?`}
								title={'Удаление сборочной единицы'}
							/>,
							document.body,
						)}
				</>
			)}
		</>
	);
};
